.MobileContactCard_title {
    padding-left: 20px;
    padding-top: 100px;
    font-size: 30pt;
    color: white;
    font-weight: 600;


}


.MobileContactCard_content {
width: 100vw;
height: inherit;
}

.MobileContactCard_content {
background-color: rgb(18, 100, 100);
background: linear-gradient(to top, rgba(18, 100, 100, 1.0) 1%, transparent 600%);


}

.MobileContactForm {
    height: fit-content;
    padding-top: 20px;
    width: calc(100%);
}

.MobileContactForm_bottom {
    height: 40vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
}

.MobileContactForm_bottom_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;

}

.MobileContactForm_bottom_left_title {
    font-size: xx-large;
    font-weight: 700;
    color: white;

}

.MobileContactForm_bottom_left_message {
    color: white;
    font-size: large;
    max-width: 50vw;
    padding-top: 10px;
}

.MobileContactForm_bottom_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;


}

.MobileContactForm_bottom_right_img {
    width: 30vw;
    max-width: 200px;
}
