.HomePage_content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 93vh;
    width: 100vw;
    text-align: center;
}

.container {
    display: flex;
    width: 100vw;
    height: calc(100vh - 50px);
    overflow-x: scroll;
 white-space: nowrap;
    overflow-y: hidden;
  }

  .container::-webkit-scrollbar {
      display: none;
  }
  
  .item {
    scroll-snap-align: center ;
    width: 100vw;
    height: inherit;
    white-space: normal;
    scroll-snap-stop: always;

    
     
   
  }