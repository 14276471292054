.LoginPage_content {
  padding-top: 70px;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(80vh - 70px);
  padding-right: 20px;
  padding-left: 20px;
}

.LoginPage_title {
  font-weight: 700;
  font-size: 40pt;
  text-transform: capitalize;
  color: rgb(18, 100, 100);
  border-bottom: solid;
  border-color: lightgray;
  border-width: 1px;
  padding-bottom: 10px;
  width: fit-content;
}

@media screen and (max-width: 800px) {
  .LoginPage_title {
    font-weight: 700;
    font-size: 30pt;
    text-transform: capitalize;
    color: rgb(18, 100, 100);
    border-bottom: solid;
    border-color: lightgray;
    border-width: 1px;
    padding-bottom: 10px;
    width: fit-content;
  }

  .LoginPage_button {
    font-size: 10pt;
  }

  .LoginPage_button:hover {
    font-size: 15pt;
  }
}

.LoginPage_button {
  color: rgb(18, 100, 100);
  text-transform: none;
  padding: 5px;
  font-size: 20pt;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: white;
  width: fit-content;
  cursor: pointer;
  padding: 10px;
}

.LoginPage_button:hover {
  background-color: rgb(18, 100, 100);
  color: white;
  cursor: pointer;
  text-transform: none;
  font-size: 20pt;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 5px;
}
