.TradesmanPage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .TradesmanPage .list {
    width: 95%;
    max-width: 800px;
  }
  
  .TradesmanPage .listitem {
    height: fit-content;
    position: relative;
    padding: 10px;
    padding-bottom: 30px;
    border-bottom: solid lightgray 1px;
  }
  
  .TradesmanPage .number {
    position: absolute;
    font-size: 30pt;
    font-weight: 700;
    color: rgb(18, 100, 100);
  }
  
  .TradesmanPage .title {
    padding-top: 50px;
    font-size: 30pt;
    text-transform: capitalize;
    font-weight: 600;
  }
  
  .TradesmanPage .message {
    margin-top: 20px;
    font-size: large;
    font-weight: 400;
  }
  
  
  .TradesmanPage .image .in { 
    opacity: 1;
    transition: 1s ease-out;
 }
 .TradesmanPage .image  .out {
    opacity: 0;
    transition: 1s ease-in;
 }