.AboutUsCard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  height: 98vh;
}

.AboutUsCard_img_side {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  transition: 2s ease-in;
}
.AboutUsCard_img_side_out {
    width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  opacity: 0;
  transition: 2s ease-out;
}

.AboutUsCard_img_div {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
}

.AboutUsCard_img {
  width: 90%;
}

.AboutUsCard_text_side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 10%;
}

.AboutUsCard_text_title {
  font-size: xx-large;
  font-weight: 600;
}

.AboutUsCard_text_sub_text {
    width: 50%;
    margin-bottom: 20px;
}