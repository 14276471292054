.HeaderNavigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.HeaderNavigation .NavItem {
  padding: 10px;
  cursor: pointer;
    margin-bottom: 4px;
    margin-left: 4px;
    position: relative;


}
.HeaderNavigation .text {
  font-size: large;
  font-weight: 500;
  color: inherit;

}


.HeaderNavigation .NavItem:hover {
  background-color: rgb(18, 100, 100);
  border-radius: 5px;
  border-left: solid 4px  rgb(237, 155, 155);
  border-bottom: solid 4px  rgb(237, 155, 155);
  color: white;
  margin-bottom: 0px;
  margin-left: 0px;
}





.Header_navigation_item_list {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Header_navigation_item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.Header_navigation_item :hover {
  border-left: solid;
  border-bottom: solid;
  border-color: rgb(237, 155, 155);
  background-color: rgb(18, 100, 100);
  color: white;
  border-width: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 90%;
  margin-top: 5%;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}

.Header_navigation_item_text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: large;
  font-weight: 600;
  text-align: center;
}



.LoginPopper {
  position: absolute;
  top: 55px;
  width: 250px;
  max-width: 50vw;
  right: 0px;
}
