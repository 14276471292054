.StillGotAQuestionCard {
    width: inherit;
    height: inherit;
    background-color: rgb(18, 100, 100);
    position: relative;
}


.StillGotAQuestionCard .message {
    position: absolute;
    bottom: 370px;
    width: 320px;
    color: white;
    right: 30px;

}
.StillGotAQuestionCard .title {

    font-size: x-large;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.StillGotAQuestionCard .comment {
    font-size: medium;
    font-weight: 600;

}
.StillGotAQuestionCard .arrow {
    width: 200px;
    position: absolute;
    bottom: 140px;
    right: 15px;

}