.PricingPage {
  height: 100vh;
  width: 100vw;
  scroll-snap-type: none; 
  overflow: scroll;
}
.PricingPage .hero {
  height: 100vh;
  width: 100vw;
  background-color: rgb(18, 100, 100);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-align: center;
}

@media screen and (min-width: 800px) {
  .PricingPage .message {
    font-size: 70pt;
    color: white;
    text-transform: capitalize;
    font-weight: 700;
    width: 100%;
    max-width: 580px;
    height: fit-content;
    text-align: center;
  }

  .PricingPage {
    height: 100vh;
    width: 100vw;
    scroll-snap-type: y mandatory; 
    overflow: scroll;
  }

}

@media screen and (max-width: 800px) {
  .PricingPage .message {
    font-size: 50pt;
    color: white;
    text-transform: capitalize;
    font-weight: 700;
    width: 100%;
    max-width: 580px;
    height: fit-content;
    text-align: center;
  }

  .PricingPage .arrow {
    display: none;
  }

  .arrow-comment {
    display: none;
  }
}

.PricingPage .arrow {
  width: 170px;
  position: absolute;
  right: 20px;
  bottom: 100px;
  max-width: 20%;
}

.arrow-comment {
  position: absolute;
  color: white;
  font-size: x-large;
  font-weight: 600;
  bottom: 220px;
  right: 200px;
  text-align: center;
}

.PricingPage .tradesman-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  height: 80vh;
  padding-bottom: 100px;
  width: 100vw;
  background-color: rgb(18, 100, 100);
  flex-wrap: wrap;
  scroll-snap-align: center;

}

@media screen and (max-width: 800px){
    .PricingPage .tradesman-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        scroll-snap-align: center;
        height: 750px;
        padding-bottom: 100px;
        width: 100vw;
        background-color: rgb(18, 100, 100);
        flex-wrap: wrap;
        scroll-snap-align: none;
      } 
}



.PricingPage .tradesman-section .title {
  text-align: center;
  font-size: large;
  text-transform: capitalize;
  margin-top: 5px;
  color: rgb(18, 100, 100);
  font-weight: 600;
  margin-top: 50px;
}

.PricingPage .tradesman-section-mobile .title {
  text-align: center;
  font-size: large;
  text-transform: capitalize;
  margin-top: 5px;
  color: rgb(18, 100, 100);
  font-weight: 600;
  margin-top: 50px;
}

.PricingPage .card {
  background-color: white;
  border-radius: 10px;
  height: 700px;
  width: 400px;
  max-width: calc(100% - 20px);
  box-shadow: rgba(255, 255, 255, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin: 20px;
  position: relative;
}

.PricingPage .button-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.PricingPage .amount-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
  color: rgb(18, 100, 100);
}
.PricingPage .amount {
  font-size: 100pt;
  font-weight: 700;
  position: relative;
  margin-right: 30px;
  text-shadow: rgb(237, 155, 155) -4px 0;
}

.PricingPage .symbol {
  font-size: 50pt;
  position: absolute;
  left: 0px;
  top: 10px;
}
.PricingPage .number {
  margin-left: 50px;
  margin-bottom: 0px;
}

.per-div {
  text-align: center;
  font-size: large;
}

.PricingPage .switch {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  right: 10px;
  top: 0px;
}

.PricingPage .label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: large;
  font-weight: 500;
}

.PricingPage .subtitle-features {
  font-size: x-large;
  font-weight: 600;
  text-transform: capitalize;
  border-bottom: solid 1px lightgray;
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 20px;
}

.PricingPage .list-item {
  width: calc(100% - 20px);
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  text-transform: capitalize;
}

.PricingPage .text {
  font-size: large;
  margin-left: 10px;
  font-weight: 500;
  margin-top: 2px;
}


.save {
    position: absolute;
    font-size: large;
    bottom: 0px;
    right: 0px;
    text-shadow: none;
}
