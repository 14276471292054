.LogoCard_content {
    height: calc(100vh);
    overflow: hidden;
}

.LogoCard_top {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    align-items: center;
    

}


.LogoCard_div {
    width: 80%;
}

.LogoCard_logo {
    width: 100%;
}


.LogoCard_bottom {
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
  
}

.LogoCard_bottom_img {
    width: 100%;
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 30.0) 5%, transparent 100%);
}
