

.AboutUsPage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    overflow-x: hidden;
  }
  
  .AboutUsPage .list {
    width: 95%;
    max-width: 800px;
  }
  
  .AboutUsPage .listitem {
    height: fit-content;
    position: relative;
    padding: 10px;
    padding-bottom: 30px;
    border-bottom: solid lightgray 1px;
  }
  
  .AboutUsPage .number {
    position: absolute;
    font-size: 30pt;
    font-weight: 700;
    color: rgb(18, 100, 100);
  }
  
  .AboutUsPage .title {
    padding-top: 50px;
    font-size: 30pt;
    text-transform: capitalize;
    font-weight: 600;
  }
  
  .AboutUsPage .message {
    margin-top: 20px;
    font-size: large;
    font-weight: 400;
  }
  
  .AboutUsPage .image .in { 
    opacity: 1;
    transition: 1s ease-out;
  }
  .AboutUsPage .image  .out {
    opacity: 0;
    transition: 1s ease-in;
  }



.AboutUsPage .image .in { 
    opacity: 1;
    transition: 1s ease-out;
 }
 .AboutUsPage .image  .out {
    opacity: 0;
    transition: 1s ease-in;
 }


 
  .AboutUsPage .member {
      margin-top: 100px;
  }

 .AboutUsPage .membertitle {
     display: flex;
     flex-direction: row;

 }


 .AboutUsPage .nametitle {
     font-size: xx-large;
     display: flex;
     flex-direction: column;
     justify-content: center;
     margin-left: 20px;
 }

 .AboutUsPage .bio {
     font-size: large;
     margin-top: 20px;



 }





 .AboutUsPage .listitembottom {
    
    position: relative;
    padding: 10px;
    margin-bottom: 20px;
    height: 1200px;
    width: calc(100% - 20px);
    margin-top: 100px;
   
   }