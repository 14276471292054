.TenantPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  overflow-x: hidden;
}

.TenantPage .list {
  width: 95%;
  max-width: 800px;
}

.TenantPage .listitem {
  height: fit-content;
  position: relative;
  padding: 10px;
  padding-bottom: 30px;
  border-bottom: solid lightgray 1px;
}

.TenantPage .number {
  position: absolute;
  font-size: 30pt;
  font-weight: 700;
  color: rgb(18, 100, 100);
}

.TenantPage .title {
  padding-top: 50px;
  font-size: 30pt;
  text-transform: capitalize;
  font-weight: 600;
}

.TenantPage .message {
  margin-top: 20px;
  font-size: large;
  font-weight: 400;
}

.TenantPage .image .in { 
  opacity: 1;
  transition: 1s ease-out;
}
.TenantPage .image  .out {
  opacity: 0;
  transition: 1s ease-in;
}