.MessagesCard {
    position: relative;
    padding: 5px;
    height: inherit;
    width: inherit;
    width: calc(100% - 5px);

}

.MessagesCard .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0.7;
    z-index: 5;

}
.MessagesCard .sub {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.MessagesCard .messages {
    width: inherit;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}



.MessagesCard .default-messages-master {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.MessagesCard .default-message {
    background-color: rgb(19, 100, 100);
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    max-width: 80%;
}
.MessagesCard .text {
    font-size: large;
    font-weight: 500;

}


.MessagesCard .send-messages-master {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.MessagesCard .send-message {
    max-width: 80%;
    padding: 10px;
    border: solid 1px rgb(19, 100, 100);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: rgb(19, 100, 100);
    text-align: right;

}




.MessagesCard .input { 
padding-top: 5px;

}