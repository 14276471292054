.MobileHomePage {

}

.MobileHomePage .list {
    height: 100vh;
    overflow: scroll;
}

.MobileHomePage .list-item {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.MobileHomePage .list-item:nth-last-child(1) {
height: 130vh;
overflow: visible;
scroll-snap-align: start;
}

.MobileHomePage .list-item:nth-child(4) {
    height: 100vh;
    background-color: red;
    scroll-snap-align: center;
    }



.MobileHomePage .content {
    position: absolute;
    bottom: 50vh;
    padding: 10px;
    width: calc(100% - 20px);
    max-width: 500px;

}

.MobileHomePage .list-item:nth-child(odd) .content {
    position: absolute;
    bottom: 50vh;
    padding: 10px;
    width: calc(100% - 20px);
    max-width: 500px;
    text-align: right;
    right: 0px;

}


.MobileHomePage .title {
    font-size: x-large;
    font-weight: 600;
    text-transform: capitalize;

}
.MobileHomePage .message {
    font-size: medium;

}


.MobileHomePage .extra {
    position: absolute;
    width: 100%;
    top: 50vh;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}

.MobileHomePage .image {
    height: calc(100% - 20px);
    width: 200px;
    margin-top: 10px;
    margin-right: 10px;
    position: relative;

}

.MobileHomePage .list-item:nth-child(odd) .extra {
    position: absolute;
    width: 100%;
    top: 50vh;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

