.Messaging_Fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20;
}



/* card */
.Messaging_Fab .messages-card-open {
    position: absolute;
    width: 400px;
    height: 500px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 150px);
    bottom: 65px;
    right: 0px;
    transition: 1s ease-in-out;
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;



}

.Messaging_Fab .messages-card {
    opacity: 0;
    transition: 1s ease-in-out;
    visibility: hidden;
    position: absolute;
    width: 400px;
    height: 500px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 150px);
    bottom: 65px;
    right: 0px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
