.EveryoneCard {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100vw;
    height: 93vh;
    margin-top: 5vh;
}

.EveryoneCard_left {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.EveryoneCard_img {
    width: 90%;
    margin-left: 5%;
    transition: 2s ease-in;
}

.EveryoneCard_img_out {
    width: 90%;
    margin-left: 5%;
    transition: 1s ease-out;
    opacity: 0;
}

.EveryoneCard_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 87%;
    width: 60%;
    margin-left: 10%;
}