.ContactCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 98vh;
    background-color: rgb(18, 100, 100);
  }

  .ContactCard_form_side {
      width: 50%;
  }

  .ContactCard_text_side {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 90%;
  }

  .ContactCard_text_div {
      color: white;
      margin-left: 20px;
  }
  .ContactCard_text_title {
    font-size: xx-large;
    font-weight: 600;
  }
  
  .ContactCard_text_sub_text {
      width: 50%;
      margin-bottom: 20px;
  }
  .ContactCard_form_side {
      display: flex;
      flex-direction: column;
      justify-content: center;
  }


  .ContactCard_img {
      width: 20%;
      margin-bottom: 20px;
  }

  .ContactCard_image_div {
     width: 98%;
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     height: 38vh;
  }

  .ContactCard_image_div_sub {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
  }

  .ContactCard_fab_comments {
      font-size: large;
      color: white;
      font-weight: 600;
      text-align: center;
      margin-right: 20px;
      margin-top: 20px;
  }