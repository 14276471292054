.TenantCard {
    display: flex;
    flex-direction: row;
    justify-content: left;
    width: 100vw;
    height: 93vh;
    margin-top: 5vh;
}

.TenantCard_left {
    width: 30%;
    height: 90%;
    display: flex;
    justify-content:center;
    flex-direction: column;
}

.TenantCard_notifaction {
    width: 80%;
    margin-left: 10%;
}

.TenantCard_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    margin-left: 5%;
    width: 70%;
}