.ContactForm_form {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    caret-color: white;
}

.ContactForm {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    caret-color: white;
}


.halfwidth_field {
    width: 48%;
    caret-color: white;
}
.ContactForm_form_half_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ContactForm_form_button_bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}



.message_loading {
    width: calc(100% + 30px);
    height: 100%;
    background-color: rgb(18, 100, 100);
    position:absolute;
    z-index: 10;
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.message_child {
    height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
