.TradesmanCard {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 94vh;
    width: inherit;
}

.TradesmanCard_img_half {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.TradesmanCard_img {
    height: 50vh;
}

.TradesmanCard_text_half {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 20%;
}

.TradesmanCard_title {
    font-size: xx-large;
    font-weight: 600;
}

.TradesmanCard_sub_text {
    width: 50%;
}