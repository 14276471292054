.LogoSlide {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow:visible;
}

.LogoSlide_image_side {
  height: 100%;
  width: 30vw;
  background-image: url('../../Images//Photos/TenackPhotoofhouse.png');
}

.LogoSlide_image {
 /*  height: 100%; */
}

.LogoSlide_logo_line {
  height: 8vh;
}
.LogoSlide_logo {
  height: 100%;
}

.LogoSlide_text_side {
  width: 60%;
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 5%;
}

.LogoSlide_text_line {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.LogoSlide_sub_text_line {
  display: flex;
  flex-direction: row;
  font-size: x-large;
  font-weight: 150;
}

.LogoSlide_text_for {
  font-size: 35pt;
  font-weight: 300;
  padding-right: 10px;
  width: 185px;
}

.LogoSlide_text_for_results {
  font-size: 35pt;
  display: flex;
  height: 55px;
  flex-direction: column;
  overflow: hidden;
  font-weight: 600;
  max-width: 550px;
  text-align: left;
  position: relative;
}


.LogoSlide_text_for_results .active {
  color:rgb(18, 100, 100);
  visibility: visible;
  transition: 800ms linear;
  top: 0px;
  opacity: 1;
}

.LogoSlide_text_for_results .hidden {
    color:rgb(18, 100, 100);
    position: absolute;
    top: 100px;
    transition: 800ms linear;
    opacity: 0;

  }

