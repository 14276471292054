.Header_background {
    width: 100vw;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(254, 254, 255);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 40;
    position:fixed;
    opacity: 0.9;
}

.Header_logo {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
}

.Header_logo_img {
    height: 80%;
}

.Header_logo_img:hover {
    cursor: pointer;
}

.nav-menu {
    position: fixed;
    height: calc(100vh - 50px);
    width: 180px;
    right: -200px;
    top: 50px;
    background-color: rgb(18, 100, 100);
    z-index: 5;
    padding: 0px;
    opacity: 0;
    transition: 600ms linear;
}

.nav-menu-open {
    position: fixed;
    height: calc(100vh - 50px);
    width: 180px;
    right: 0px;
    top: 50px;
    background-color: rgb(18, 100, 100);
    transition: 600ms linear;
    z-index: 5;
    box-shadow: -19px -1px 7px 0px rgba(18,100,100,0.2);
    padding: 10px;
    opacity: 1;


}

.side-bar { 
    height: 46px;
    width: 50px;
    position: absolute;
    border-radius: 7.5px;
    right: 10px;
    top: 0px
}

.side-bar span {
    height: 3px;
    width: 32.5px;
    display: block;
    background-color: rgb(18, 100, 100);
    position: absolute;
    left: 9px;
    transition: all 0.3s ease-in-out;
}

.side-bar:hover {
    cursor: pointer;
}


.side-bar-open { 
    height: 46px;
    width: 50px;
    position: absolute;
    border-radius: 7.5px;
    right: 10px;
    top: 0px
}

.side-bar-open span {
    height: 3px;
    width: 32.5px;
    display: block;
    position: absolute;
    background-color: rgb(18, 100, 100);
    left: 9px;
    transition: all 0.3s ease-in-out;
}

.side-bar-open:hover {
    cursor: pointer;
}

.side-bar span:nth-child(1) {
    top: 15px;
}

.side-bar span:nth-child(2) {
    top: 22.5px;
}
.side-bar span:nth-child(3) {
    top: 31px;
}
.side-bar-open span:nth-child(1) {
    transform: rotate(45deg);
    top: 21.5px;
    left: 8.5px;
}

.side-bar-open span:nth-child(2) {
    width: 0%;
    height: 0%;
    opacity: 0;
    top: 22.5px;
}

.side-bar-open span:nth-child(3) {
    transform: rotate(-45deg);
    top: 22.5px;
    left: 8px;
}




.nav-menu-open .nav-item {
    padding: 10px;
    cursor: pointer;
      margin-bottom: 4px;
      margin-left: 4px;
  
  
  }
  .nav-menu-open .text {
    font-size: large;
    font-weight: 600;
    color: white;
    text-align: right;
  
  }
  
  
  .nav-menu-open .nav-item:hover {
    background-color: rgb(18, 100, 100);
    border-radius: 5px;
    border-left: solid 4px  rgb(237, 155, 155);
    border-bottom: solid 4px  rgb(237, 155, 155);
    border-top: solid 1px  rgb(237, 155, 155);
    border-right: solid 1px  rgb(237, 155, 155);
    color: white;
    margin-bottom: 0px;
    margin-left: 0px;
  }



.nav-menu .nav-item {
    padding: 10px;
    cursor: pointer;
      margin-bottom: 4px;
      margin-left: 4px;
  
  
  }
  .nav-menu .text {
    font-size: large;
    font-weight: 600;
    color: white;
    text-align: right;
  
  }
  
  
  .nav-menu .nav-item:hover {
    background-color: rgb(18, 100, 100);
    border-radius: 5px;
    border-left: solid 4px  rgb(237, 155, 155);
    border-bottom: solid 4px  rgb(237, 155, 155);
    border-top: solid 1px  rgb(237, 155, 155);
    border-right: solid 1px  rgb(237, 155, 155);
    color: white;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  