.InvestorCard {
    width: 100vw;
    height: 94vh;
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: left;
}

.InvestorCard_img_half {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 5%;
    width: 30%;
    height: 92%;
}

.InvestorCard_cost_line {
    font-size: 60pt;
    font-weight: 600;
}
.InvestorCard_cost_title_line {
    font-size: xx-large;
}

.InvestorCard_text_lines {
    width: 100%;
    text-align: center;
}

.InvestorCard_text_half {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 60%;
}

.Investor_Card_title {
    font-size: xx-large;
    font-weight: 600;
}

.InvestorCard_sub_text {
    width: 60%;
}